
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'forgot-password',
  components: {},
  setup() {
    const hasTokenBearer = computed(() => false);

    return {
      hasTokenBearer,
    };
  },
});
